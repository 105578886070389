<template>
  <!-- 购买统计-柱图 -->
  <div>
    <div class="myChart-line" ref="myChart05"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    DataList: {
      type: Array,
      default: () => [],
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    myChartInit() {
      var myChart = echarts.getInstanceByDom(this.$refs.myChart05);
      if (!myChart) {
        myChart = echarts.init(this.$refs.myChart05);
      }
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            saveAsImage: { show: true },
          },
        },
        legend: {
          data: ["未购", "已购", "购买率"],
        },
        grid: {
          left: "70px",
          right: "50px",
          bottom: "30px",
          top: "80px"
        },
        xAxis: [
          {
            type: "category",
            data: ["家长", "从业者", "机构", "双重身份"],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "未购/已购",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value} 人",
            },
          },
          {
            type: "value",
            name: "购买率",
            splitLine: {
              // x轴网格
              show: false,
            },
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: "{value} %",
            },
          },
        ],
        series: [
          {
            name: "未购",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 人";
              },
            },
            data: this.DataList.map((item) => item.name),
            itemStyle:{
              color:'#73C0DE'
            }
          },
          {
            name: "已购",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 人";
              },
            },
            data: this.DataList.map((item) => item.value),
            itemStyle:{
              color:'#FAC858'
            }
          },
          {
            name: "购买率",
            type: "line",
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + " %";
              },
            },
            data: this.DataList.map((item) => (item.value2 * 100).toFixed(2)),
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.myChartInit();
      }, 200);
    });
  },
  watch: {
    DataList() {
      this.$nextTick(() => {
        this.myChartInit();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myChart-line {
  min-width: 600px;
  height: 300px;
}
</style>
