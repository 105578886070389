<template>
  <!-- pvuv用户日活量-折线图 -->
  <div>
    <div class="myChart-line" ref="myChart04"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    DataList: {
      type: Object,
      default: () => {},
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    myChartInit() {
      var myChart = echarts.getInstanceByDom(this.$refs.myChart04);
      if (!myChart) {
        myChart = echarts.init(this.$refs.myChart04);
      }
      var option = {
        title: {
          // text: 'Stacked Area Chart'
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: ["机构", "个人",],
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            saveAsImage: { show: true },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.DataList.xData,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位（人）"
          },
        ],
        series: [
          {
            name: "机构",
            type: "line",
            stack: "Total",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: this.DataList.orgData,
            tooltip: {
              valueFormatter: function (value) {
                return value + " 人";
              },
            },
            // itemStyle: {
            //   // 点的颜色。
            //   color: '#0EF083'
            // },
            // areaStyle: {
            //   color: {
            //     type: 'linear',
            //     x: 0,
            //     y: 0,
            //     x2: 0,
            //     y2: 1,
            //     colorStops: [{
            //         offset: 0, color: 'rgba(14, 239, 131, 0.1700)' // 0% 处的颜色
            //     }, {
            //         offset: 1, color: 'rgba(196, 196, 196, 0)' // 100% 处的颜色
            //     }],
            //     global: false // 缺省为 false
            //   }
            // },
            // symbolSize: 0,
            smooth: true, // 线条改曲线
          },
          {
            name: "个人",
            type: "line",
            stack: "Total",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: this.DataList.personData,
            tooltip: {
              valueFormatter: function (value) {
                return value + " 人";
              },
            },
            // itemStyle: {
            //   // 点的颜色。
            //   color: '#0EF083'
            // },
            // areaStyle: {
            //   color: {
            //     type: 'linear',
            //     x: 0,
            //     y: 0,
            //     x2: 0,
            //     y2: 1,
            //     colorStops: [{
            //         offset: 0, color: 'rgba(14, 239, 131, 0.1700)' // 0% 处的颜色
            //     }, {
            //         offset: 1, color: 'rgba(196, 196, 196, 0)' // 100% 处的颜色
            //     }],
            //     global: false // 缺省为 false
            //   }
            // },
            // symbolSize: 0,
            smooth: true, // 线条改曲线
          }
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.myChartInit();
      }, 200);
    });
  },
  watch: {
    DataList() {
      this.$nextTick(() => {
        this.myChartInit();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myChart-line {
  min-width: 700px;
  height: 300px;
}
</style>
