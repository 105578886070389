<template>
  <!-- 商品回款数据-折线图 -->
  <div>
    <div class="myChart-line" ref="myChart01"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    DataList: {
      type: Object,
      default: () => {},
    },
    status: {
      type: Number,
      default: () => 99,
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    myChartInit() {
      var _that = this
      var myChart = echarts.getInstanceByDom(this.$refs.myChart01);
      if (!myChart) {
        myChart = echarts.init(this.$refs.myChart01);
      }
      var option = {
        grid: {
          left: "75px",
          right: "50px",
          bottom: "70px",
        },
        tooltip: {
          trigger: "axis",
          showContent: true,
          formatter: function (params) {
            var res = "<div><p>" + "商品回款数据" + "（" +  (_that.status == 1 ? '日' : _that.status == 2 ? '周' : _that.status == 3 ? '月' : '年') + "）" + "</p></div>";
            for (var i = 0; i < params.length; i++) {
              res +=
                "<p>" + (_that.status == 99 ? params[i].seriesName + '-' + params[i].name : params[i].name) + "：" + params[i].value + "（元）" + "</p>";
            }
            return res;
          },
        },
        legend: {
          data: this.DataList.legend,
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            saveAsImage: { show: true },
          },
        },
        xAxis: {
          type: "category",
          // data: this.DataList.xData,
          data: this.DataList.xData.map((item) => _that.status == 99 ? item.substring(5,7)+'月' : item),
          axisLabel: {
            showMaxLabel: true,
            interval: 0,
            rotate: 40,
            margin: 20
          },
          axisTick: {
            // x轴刻度线显隐
            show: false,
          },
        },
        yAxis: {
          type: "value",
          name: "单位（元）"
        },
        series: this.DataList.series,
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.myChartInit();
      }, 200);
    });
  },
  watch: {
    DataList() {
      console.log(11);
      this.$nextTick(() => {
        this.myChartInit();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myChart-line {
  min-width: 600px;
  height: 320px;
}
</style>
