<script>
import { getData } from "@/unit/common";
import { fun_formatData } from '@/unit/fun.js'
import moment from "moment";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    // 限制只能选今天及之前的日期（今天可选中）
    fun_disDate(current) {
      return current && current > moment().endOf("day");
    },

    // 机构时间段查询
    selectChange(e){
      const YearMonth = moment(e[0]).format("YYYY-MM-DD") + " 00:00:00";
      const DayLast = moment(e[1]).format("YYYY-MM-DD") + " 23:59:59"; 
      this.getList("OrgConsumeLamp", 9, YearMonth, DayLast); 
      this.getList("OrgProductLamp", 9, YearMonth, DayLast); 
    },

    // 活跃时间段查询
    selectActiveChange(e,type){
      const YearMonth = moment(e[0]).format("YYYY-MM-DD") + " 00:00:00";
      const DayLast = moment(e[1]).format("YYYY-MM-DD") + " 23:59:59"; 
      this.getList("AppStatistics", 9, YearMonth, DayLast, undefined, type); 
    },

    // 条件查询（日、周、月）
    onSearchClick(index, type) {
      let Index = type + "Index";
      let Year = type + "Year";

      if (this[Index] == index) return; // 同条件查询防抖

      this[Year] = null;
      this[Index] = index;
      this.getList(type, index);
      if (type == "Return") {
        this.yearList = []
        this.ReturnList = {
          legend: [],
          xData: [],
          series:[],
        }
      }
      if (type == "Signup") {
        // 报名模块 需要双查询
        this.getList("SignupLamp", index);
      }
    },

    // 弹出日历框
    openChange(e, type) {
      if (e) {
        let PickerShow = type + "PickerShow";

        this[PickerShow] = true; // 日期框打开
      }
    },
    // 条件查询（年）
    panelChange(e, type) {
      let time = moment(e).format("YYYY");

      let Year = type + "Year";
      let PickerShow = type + "PickerShow";
      let Index = type + "Index";

      this[Year] = moment(time, "YYYY"); // 转换为日期型
      this[PickerShow] = false; // 日期框关闭
      this[Index] = undefined;

      this.getList(type, 4, time);
      if (type == "Signup") {
        // 报名模块 需要双查询
        this.getList("SignupLamp", 4, time);
      }
    },

    // 条件查询（月）
    panelMonthChange(e, type) {
      let time = moment(e).format("YYYY-MM");
      let Year = type + "Year";
      let PickerShow = type + "PickerShow";

      this[Year] = moment(time, "YYYY-MM"); // 转换为日期型
      this[PickerShow] = false; // 日期框关闭

      const YearMonth = time + "-01 00:00:00";
      this.getList(type, 5, YearMonth);
    },

    // 清空日期框
    clearChange(type) {
      let Index = type + "Index";

      this[Index] = 3;
      this.getList(type, this[Index]);
      if (type == "Signup") {
        // 报名模块 需要双查询
        this.getList("SignupLamp", this[Index]);
      }
    },

    onMoreYearChange(arry){
      
      if(arry.length){
        this.ReturnIndex = undefined;
        clearTimeout(this.tiemID);
        this.tiemID = setTimeout(() => {
          this.ReturnList = {
            legend: [],
            xData: [],
            series:[],
          }
          arry.forEach(element => {
            this.getList("Return", 4, element);
          });
        }, 1000);
      } else {
        this.ReturnList = {
          legend: [],
          xData: [],
          series:[],
        }
        this.ReturnIndex = 3;
        this.getList("Return", this.ReturnIndex);
      }
    },

    openProductClick(type){
      // type 1.查询商品 2.查询课程
      this.productShow = true
      this.productType = type
      if( type == 1){
        this.productForm.type = undefined
        this.selectedProductRowKeys = this.returnProductId
      } else if ( type == 2 ) {
        this.productForm.type = 1
        this.selectedProductRowKeys = this.courseCompareProductId
      } else if ( type == 3 ) {
        this.productForm.type = 1
        this.selectedProductRowKeys = this.MapProductId
      }
      this.productForm.size = 10
      this.productForm.page = 1
      this.productForm.name = ''
      this.getProductList()
    },

    // 回款-清空所选商品
    onClearProduct(type){
      if(type == 1){
        this.returnObj = {
          key: 0,
          name: ''
        }
        this.ReturnList = {
          legend: [],
          xData: [],
          series:[],
        }
        if(this.ReturnIndex){
          this.returnProductId = []
          this.getList("Return", this.ReturnIndex);
        } else {
          this.yearList.forEach(element => {
            this.getList("Return", 4, element);
          });
          // let time = moment(this.ReturnYear).format("YYYY");
          // this.getList("Return", 4, time);
        }
      } else if (type == 2){
        this.mapObj = {
          key: 0,
          name: ''
        }
        if(this.ProvinceSignupIndex >= 0){
          this.MapProductId = []
          this.getList("ProvinceSignup", this.ProvinceSignupIndex);
        } else {
          let time = moment(this.ProvinceSignupYear).format("YYYY");
          this.getList("ProvinceSignup", 4, time);
        }
      }
    },

    // 确认查询
    onAsk(){
      if( this.productType == 1){ // 回款数据
        this.returnObj = {
          key: this.selectedProductDetail.productId,
          name: this.selectedProductDetail.productName
        }
        this.ReturnList = {
          legend: [],
          xData: [],
          series:[],
        }
        if(this.ReturnIndex){
          this.getList("Return", this.ReturnIndex, undefined, undefined, this.selectedProductDetail.productId);
        } else {
          this.yearList.forEach(element => {
            this.getList("Return", 4, element, undefined, this.selectedProductDetail.productId);
          });
          // console.log(this.yearList);
          // console.log('哈喽');
          // let time = moment(this.ReturnYear).format("YYYY");
        }
      } else if ( this.productType == 2 ) {
        this.CourseCompareObj = {
          key: this.selectedProductDetail.objectId,
          name: this.selectedProductDetail.productName,
          productId: this.selectedProductDetail.productId
        }
        let time = moment(this.CourseCompareYear).format("YYYY-MM") + "-01 00:00:00";
        this.getList("CourseCompare", 5, time, undefined, this.selectedProductDetail.objectId);
      } else if ( this.productType == 3 ) {
        this.mapObj = {
          key: this.selectedProductDetail.objectId,
          name: this.selectedProductDetail.productName
        }
        if(this.ProvinceSignupIndex >= 0){
          this.getList("ProvinceSignup", this.ProvinceSignupIndex, undefined, undefined, this.selectedProductDetail.objectId);
        } else {
          let time = moment(this.ProvinceSignupYear).format("YYYY");
          this.getList("ProvinceSignup", 4, time, undefined, this.selectedProductDetail.productId);
        }
      } 
      this.productShow = false
    },

    // 选择商品
    onSelectProduct(selectedRowKeys,v){
      this.selectedProductRowKeys = selectedRowKeys
      this.selectedProductDetail = v[0]

      if(this.productType == 1){
        this.returnProductId = selectedRowKeys
      } else if ( this.productType == 2 ){
        this.courseCompareProductId = selectedRowKeys
      } else if ( this.productType == 3 ){
        this.MapProductId = selectedRowKeys
      }
    },

    // 商品高级检索
    onProductSearch(){
      this.productForm.page = 1
      this.getProductList()
    },

    // 商品分页
    onProductPage(e) {
      this.productForm.page = e.current;
      this.productForm.size = e.pageSize;
      this.getProductList()
    },

    // 检索商品列表
    getProductList(type) {
      this.productLoad = true
      this.$ajax({
        method: 'get',
        url: '/hxclass-management/product-archives/manage/select-win',
        params: this.productForm
      }).then((res) => {
        this.productLoad = false
        if (res.code == 200 && res.success) {
          this.productTotal = res.data.total;
          this.productData = res.data.records;

          if(type == 999 && res.data.records.length){
            this.CourseCompareObj.name = res.data.records[0].productName
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 公共查询数据
    getList(status, dateType, time1, time2, bizId, type) {
      // status：(模块名称)
      // dateType：(0-全部,1-本日,2-本周,3-本月,4-指定年份,5-指定月份,6-本年，9-自定义起始结束日期)
      // time1：(自定义起始日期或指定年份)
      // time2：(自定义结束日期)
      // bizId：(课程/商品id)
      // type：(1-活跃人数  2-总人数 3-启动数 4-新增人数)
      let dataJson = {
        dateType: dateType,
        time1: time1,
        time2: time2,
        bizId: bizId,
        type: type,
      };

      let pName = "";
      if (status == "Total") {
        // 总体数据
        pName = "rpt_totalData";
      } else if (status == "Return") {
        this.yaerShow = false
        // 回款数据
        pName = "rpt_productDataChart";

        dataJson.bizId = this.returnObj.key ? this.returnObj.key : undefined
      } else if (status == "SalesPrice") {
        // 销售回款数据
        pName = "rpt_salesPriceData";
      } else if (status == "CourseCompare") {
        // 报名人数、金额
        pName = "rpt_courseCompareData";

        dataJson.bizId = this.CourseCompareObj.key ? this.CourseCompareObj.key : 1
        dataJson.productId = this.CourseCompareObj.productId ? this.CourseCompareObj.productId : 1
      } else if (status == "Signup") {
        // 报名数据
        pName = "rpt_courseSignUpChart";
      } else if (status == "SignupLamp") {
        // 报名-跑马灯数据
        pName = "rpt_courseSignUpData";
      } else if (status == "OrgConsumeLamp") {
        // 机构消费排行-跑马灯数据
        pName = "rpt_orgConsumeData";
      } else if (status == "OrgProductLamp") {
        // 机构商品销量排行-跑马灯数据
        pName = "rpt_orgProductSalesVolume";
      } else if (status == "PVUV") {
        // pv、uv统计
        pName = "rpt_PVUVChart";
      } else if (status == "NewUser") {
        // 新增注册数据
        pName = "rpt_newUserCompare";
      } else if (status == "MonthActive") {
        // 新增月活数据
        pName = "rpt_monthActiveData";
      } else if (status == "AppStatistics") {
        // 新增月活数据
        pName = "rpt_appStatistics";
      } else if (status == "User") {
        // 新增用户
        pName = "rpt_newUserChart";
      } else if (status == "Role") {
        // 身份分布
        pName = "rpt_getIdentityChart";
      } else if (status == "Buy") {
        // 购买统计
        pName = "rpt_buyChart";
      } else if (status == "ProvinceSignup") {
        // 省份报名人数
        pName = "rpt_areaDisData";
        dataJson.bizId = this.mapObj.key ? this.mapObj.key : undefined
      }

      let params = {
        inString: JSON.stringify(dataJson),
        pName: pName,
      };
      getData(params).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data) {
            // 有数据
            let resultData = fun_formatData(res.data);
            // 数据格式根据模块需求进行二次处理
            if (status == "Total") {
              // 总体数据
              this.TotalForm = {
                number1: resultData.data[0].value,
                number2: resultData.data[1].value,
                total: resultData.data[2].value,
              };
            } else if (status == "Return") {
              this.ReturnList.legend.push(time1 ? String(time1) : time1)
              this.ReturnList.xData = resultData.data.map((item) => this.ReturnIndex == 1 ? item.name.substring(10,16) : item.name)
              this.ReturnList.series.push({
                data: resultData.data.map((item) => item.value),
                name: time1,
                type: "line",
                symbol: "triangle", // 数据点样式
                symbolSize: 14,
              })
              this.yaerShow = true
            } else if (status == "SalesPrice") {
              // 销售回款数据
              this.SalesPriceForm = {
                price: resultData.data[0].name,
                yoy: resultData.data[0].value,
                qoq: resultData.data[0].value2,
              };
            } else if (status == "CourseCompare") {
              // 报名人数、金额数据
              this.CourseCompareForm = {
                data1: {
                  people: resultData.data[0].name, // 人数
                  yoy: resultData.data[0].value, // 同比
                  qoq: resultData.data[0].value2, // 环比
                },
                data2: {
                  price: resultData.data[1].name, // 销售额
                  yoy: resultData.data[1].value, // 同比
                  qoq: resultData.data[1].value2, // 环比
                },
              };
            } else if (status == "Signup") {
              // 报名数据
              this.SignupList = [];
              let arry = []; // 暂存数组
              resultData.data.forEach((element) => {
                if (element.name) {
                  arry.push({
                    name: element.name,
                    value: Number(element.value),
                    price:
                      element.value2 != "0.00" ? Number(element.value2) : 0,
                    category: element.value3,
                    children: [],
                  });
                }
              });

              // 按分类分组
              function fun_groupBy(objectArray, key) {
                var newObj = {};
                objectArray.forEach(function (obj) {
                  var array = newObj[obj[key]] || [];
                  array.push(obj);
                  newObj[obj[key]] = array;
                });
                return newObj;
              }
              let viewGroup = fun_groupBy(arry, "category");

              //计算对象数组中某个属性合计
              function fun_countTotal(arr, keyName) {
                let $total = 0;
                $total = arr.reduce(function (
                  total,
                  currentValue,
                  currentIndex,
                  arr
                ) {
                  return currentValue[keyName]
                    ? total + currentValue[keyName]
                    : total;
                },
                0);
                return $total;
              }

              let map = viewGroup;
              // 课程类型：1-免费课程  2-专题微课 3-线下培训 106-证书课程
              for (let item in map) {
                this.SignupList.push({
                  name:
                    item == 1
                      ? "免费课程"
                      : item == 2
                      ? "专题微课"
                      : item == 3
                      ? "线下培训"
                      : item == 106
                      ? "证书课程"
                      : "",
                  value: fun_countTotal(viewGroup[item], "value"),
                  children: viewGroup[item],
                });
              }
            } else if (status == "SignupLamp") {
              // 报名-跑马灯数据
              let arry = []
              resultData.data.forEach((element) => {
                if (element.name) {
                  arry.push({
                    name: element.name,
                    value: element.value,
                    price: element.value2,
                    categorName: element.value3 == 1
                      ? "免费课程"
                      : element.value3 == 2
                      ? "专题微课"
                      : element.value3 == 3
                      ? "线下培训"
                      : element.value3 == 106
                      ? "证书课程"
                      : "-",
                  });
                }
              });
              this.signupLampList = arry;
            } else if (status == "OrgConsumeLamp") {
              // 机构消费排行-跑马灯数据
              let arry = []
              resultData.data.forEach((element) => {
                if (element.name) {
                  arry.push({
                    name: element.name,
                    value: element.value,
                    price: element.value2,
                  });
                }
              });
              this.orgConsumeLampList = arry;
            } else if (status == "OrgProductLamp") {
              // 机构商品销量排行-跑马灯数据
              let arry = []
              resultData.data.forEach((element) => {
                if (element.name) {
                  arry.push({
                    name: element.name,
                    productName: element.value ? element.value : "-",
                    value: element.value2 ? element.value2 : "0",
                    price: element.value3 ? element.value3 : "0",
                  });
                }
              });
              this.orgProductLampList = arry;
            } else if (status == "PVUV") {
              let iosData = [];
              let androidData = [];
              let pvData = [];
              let xData = [];
              resultData.data.forEach((element) => {
                if (element.name) {
                  if (element.value2 == "1") {
                    // Ios
                    iosData.push(element.value);
                  } else if (element.value2 == "2") {
                    // Android
                    androidData.push(element.value);
                  } else if (element.value2 == "3") {
                    // pv
                    pvData.push(element.value);
                  }
                  if (xData.indexOf(element.name) < 0) {
                    xData.push(element.name);
                  }
                }
              });
              this.PVUVForm = {
                iosData: iosData,
                androidData: androidData,
                pvData: pvData,
                xData: xData,
              };
            } else if (status == "NewUser") {
              // 新增注册数据
              this.NewUserForm = {
                people: resultData.data[0].name,
                yoy: resultData.data[0].value,
                qoq: resultData.data[0].value2,
              };
            } else if (status == "AppStatistics") {
              let iosData = [];
              let androidData = [];
              let totalData = [];
              let xData = [];
              // console.log(resultData.data);
              resultData.data.forEach((element) => {
                if (element.name) {
                  if (element.value2 == "1") {
                    // Ios
                    iosData.push(element.value);
                  } else if (element.value2 == "2") {
                    // Android
                    androidData.push(element.value);
                  } else if (element.value2 == "3") {
                    // total
                    totalData.push(element.value);
                  }
                  if (xData.indexOf(element.name) < 0) {
                    xData.push(element.name);
                  }
                }
              });
              if(type == 1){ // 活跃人数数据
                this.ActiveForm = {
                  iosData: iosData,
                  androidData: androidData,
                  totalData: totalData,
                  xData: xData,
                };
              } else if(type == 2){  // 总人数数据
                this.TotalPeopleForm = {
                  iosData: iosData,
                  androidData: androidData,
                  totalData: totalData,
                  xData: xData,
                };
              } else if(type == 3){ // 启动数数据
                this.StartsForm = {
                  iosData: iosData,
                  androidData: androidData,
                  totalData: totalData,
                  xData: xData,
                };
              } else if(type == 4){ // 新增人数数据
                this.NewAddForm = {
                  iosData: iosData,
                  androidData: androidData,
                  totalData: totalData,
                  xData: xData,
                };
              } 
            } else if (status == "MonthActive") {
              // 新增月活数据
              this.MonthActiveForm = {
                people: resultData.data[0].name,
                yoy: resultData.data[0].value,
                qoq: resultData.data[0].value2,
              };
            } else if (status == "User") {
              let personData = [];
              let orgData = [];
              let xData = [];
              resultData.data.forEach((element) => {
                if (element.name) {
                  if (element.value2 == "0") {
                    // 个人
                    personData.push(element.value);
                  } else if (element.value2 == "2") {
                    // 机构
                    orgData.push(element.value);
                  }
                  if (xData.indexOf(element.name) < 0) {
                    xData.push(element.name);
                  }
                }
              });
              this.UserForm = {
                personData: personData,
                orgData: orgData,
                xData: xData,
              };
            } else if (status == "Role") {
              // 身份分布数据
              // 用户类型：类型：00.普通用户，01.家长，02从业者，03.机构，04.双重身份，05.家长+机构，06.从业者+机构，07 家长+从业者+机构 08 老师
              let roleType = [
                "普通用户",
                "家长",
                "从业者",
                "机构",
                "双重身份",
                "家长+机构",
                "从业者+机构",
                "家长+从业者+机构",
                "老师",
                "机构-未认证",
              ];
              this.RoleList = [];
              resultData.data.forEach((element) => {
                this.RoleList.push({
                  name: roleType[Number(element.name)],
                  value: element.value,
                });
              });
            } else if (status == "Buy") {
              // 省份报名人数
              this.BuyList = resultData.data;
            } else if (status == "ProvinceSignup") {
              resultData.data.forEach(element => {
                if(element.name){
                  element.name = this.fun_cityName(element.name)
                }
                if(!element.name){
                  this.unknown = element.value
                }
              });
              // 省份报名人数
              resultData.data.push({ name: "南海诸岛", value: 0 });
              this.ProvinceSignupList = resultData.data;
            }
          } else {
            // 查询无数据，根据模块对数据初始化
            if (status == "Total") {
              this.TotalForm = {
                number1: 0,
                number2: 0,
                total: 0,
              };
            } else if (status == "Return") {
              this.ReturnList = {
                legend: [],
                xData: [],
                series:[],
              }
            } else if (status == "SalesPrice") {
              this.SalesPriceForm = {
                price: 0.0,
                yoy: 0,
                qoq: 0,
              };
            } else if (status == "CourseCompare") {
              this.CourseCompareForm = {
                data1: {
                  people: 0, // 人数
                  yoy: 0, // 同比
                  qoq: 0, // 环比
                },
                data2: {
                  price: 0.0, // 销售额
                  yoy: 0, // 同比
                  qoq: 0, // 环比
                },
              };
            } else if (status == "Signup") {
              this.SignupList = [];
            } else if (status == "SignupLamp") {
              this.signupLampList = [];
            } else if (status == "OrgConsumeLamp") {
              this.orgConsumeLampList = [];
            } else if (status == "OrgProductLamp") {
              this.orgProductLampList = [];
            } else if (status == "PVUV") {
              this.PVUVForm = {
                iosData: [],
                androidData: [],
                pvData: [],
                xData: [],
              };
            } else if (status == "NewUser") {
              this.NewUserForm = {
                people: 0.0,
                yoy: 0,
                qoq: 0,
              };
            } else if (status == "AppStatistics") {
              if(type == 1){ // 活跃人数数据
                this.ActiveForm = {
                  iosData: [],
                  androidData: [],
                  totalData: [],
                  xData: [],
                };
              } else if(type == 2){  // 总人数数据
                this.TotalPeopleForm = {
                  iosData: [],
                  androidData: [],
                  totalData: [],
                  xData: [],
                };
              } else if(type == 3){ // 启动数数据
                this.StartsForm = {
                  iosData: [],
                  androidData: [],
                  totalData: [],
                  xData: [],
                };
              } else if(type == 4){ // 新增人数数据
                this.NewAddForm = {
                  iosData: [],
                  androidData: [],
                  totalData: [],
                  xData: [],
                };
              } 
            } else if (status == "MonthActive") {
              this.MonthActiveForm = {
                people: 0.0,
                yoy: 0,
                qoq: 0,
              };
            } else if (status == "User") {
              this.UserForm = {
                personData: [],
                orgData: [],
                xData: [],
              };
            } else if (status == "Role") {
              this.RoleList = [];
            } else if (status == "Buy") {
              this.BuyList = [];
            } else if (status == "ProvinceSignup") {
              this.ProvinceSignupList = [];
              this.unknown = 0
            }
          }
        } else if (res.code == 500) {
          let resultMessage = fun_formatData(res.message);
          this.$message.error(resultMessage.message);
        }
      });
    },

    // 数组按某字段排序
    fun_sort(list, name, field, sort) {
      // list 需要排序的数组
      // name 模块名称
      // field 按该字段进行排序
      // sort 正序和倒叙
      let LampList = name + 'LampList'
      let isShow = name + 'Show'
      this[isShow] = false
      function sortList(a, b) {
        if (sort == "up") {
          return a[field] - b[field];
        } else if (sort == "down") {
          return b[field] - a[field];
        }
      }
      this.$set(this,LampList ,list.sort(sortList))
      setTimeout(()=>{
        this[isShow] = true
      },200)
    },

    // 处理城市名称简写
    fun_cityName(cityName) {
      if (cityName == '广西壮族自治区') {
        return cityName.replace('壮族自治区', '');
      } else if (cityName == '宁夏回族自治区') {
        return cityName.replace('回族自治区', '');
      } else if (cityName == '新疆维吾尔自治区') {
        return cityName.replace('维吾尔自治区', '');
      } else if (cityName.indexOf('省') > -1) {
        return cityName.replace('省', '');
      } else if (cityName.indexOf('市') > -1) {
        return cityName.replace('市', '');
      } else if (cityName.indexOf('自治区') > -1) {
        return cityName.replace('自治区', '');
      } else if (cityName.indexOf('特别行政区') > -1) {
        return cityName.replace('特别行政区', '');
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    const date = new Date();
    const Year = date.getFullYear();
    const Month = ((date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1));
    const Day = date.getDate();
    const YearMonth = Year + "-" + Month + "-01 00:00:00"; // 查询年月数据需要拼接该格式
    const DayLast = Year + "-" + Month + "-" + Day + " 23:59:59"; 

    this.SalesPriceYear = moment(YearMonth, "YYYY-MM");
    this.CourseCompareYear = moment(YearMonth, "YYYY-MM");
    this.OrgYear = [Year + "-" + Month + "-" + '01', Year + "-" + Month + "-" + Day]
    this.ActiveYear = [Year + "-" + Month + "-" + '01', Year + "-" + Month + "-" + Day]
    this.TotalPeopleYear = [Year + "-" + Month + "-" + '01', Year + "-" + Month + "-" + Day]
    this.StartsYear = [Year + "-" + Month + "-" + '01', Year + "-" + Month + "-" + Day]
    this.NewAddYear = [Year + "-" + Month + "-" + '01', Year + "-" + Month + "-" + Day]
    this.NewUserYear = moment(YearMonth, "YYYY-MM");
    this.MonthActiveYear = moment(YearMonth, "YYYY-MM");

    this.getProductList(999)
    
    // 注：变量首字母需大写
    this.getList("Total", 3); // 查询总体数据
    this.getList("Return", 3); // 查询回款数据
    this.getList("SalesPrice", 5, YearMonth); // 查询销售回款数据
    this.getList("CourseCompare", 5, YearMonth, undefined, 1); // 查询报名人数、金额
    this.getList("Signup", 3); // 查询报名数据
    this.getList("SignupLamp", 0); // 查询报名-跑马灯数据
    this.getList("OrgConsumeLamp", 9, YearMonth, DayLast); // 机构消费排行-跑马灯数据
    this.getList("OrgProductLamp", 9, YearMonth, DayLast); // 机构消费排行-跑马灯数据
    this.getList("PVUV", 3); // pv、uv统计数据
    this.getList("NewUser", 5, YearMonth); // 查询新增注册人数数据
    this.getList("MonthActive", 5, YearMonth); // 查询新增月活人数数据
    this.getList("AppStatistics", 9, YearMonth, DayLast, undefined, 1); // 活跃人数数据
    this.getList("AppStatistics", 9, YearMonth, DayLast, undefined, 2); // 总人数数据
    this.getList("AppStatistics", 9, YearMonth, DayLast, undefined, 3); // 启动数数据
    this.getList("AppStatistics", 9, YearMonth, DayLast, undefined, 4); // 新增人数数据
    this.getList("User", 3); // 查询新增用户
    this.getList("Role", 3); // 查询角色分布
    this.getList("Buy", 3); // 查询购买统计
    this.getList("ProvinceSignup", 3); // 查询省份报名人数
  },
  // 生命周期-实例挂载后调用
  mounted() {
    // 获取2020年至今年的所有年份
    const currentYear = new Date().getFullYear();
    const startYear = 2020;
    this.yearArry = []
    for (let year = startYear; year <= currentYear; year++) {
      this.yearArry.push(year);
    }
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped></style>
