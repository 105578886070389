<template>
  <!-- 数据中心 -->
  <div id="Data-center" class="main-wrap">
    <!-- 总体数据 -->
    <div class="screen-data">
      <div class="flex-between">
        <h3 class="head-title">总体数据</h3>
        <div class="box_time">
          <div class="time-item" :class="{'time-item-hover' : TotalIndex == item.index}" @click="onSearchClick(item.index, 'Total')" v-for="(item) in timeList" :key="item.index + '$01'">{{item.name}}</div>
          <a-date-picker 
            v-model="TotalYear" 
            :open='TotalPickerShow'  
            @openChange="openChange($event,'Total')" 
            @panelChange="panelChange($event,'Total')" 
            @change="clearChange('Total')"
            placeholder="年度" 
            format="YYYY" 
            class="picker" 
            mode="year" />
        </div>
      </div>
      <div class="data-ul">
        <div class="item-li">
          <img class="icon" src="@/assets/image/dataCenter/data-icon01.png" alt="">
          <div class="side-r">
            <p class="title">平台注册人数（个人）</p>
            <p class="num">{{TotalForm.number1}}<i>人</i></p>
          </div>
        </div>
        <div class="item-li">
          <img class="icon" src="@/assets/image/dataCenter/data-icon02.png" alt="">
          <div class="side-r">
            <p class="title">平台注册人数（机构）</p>
            <p class="num">{{TotalForm.number2}}<i>家</i></p>
          </div>
        </div>
        <div class="item-li">
          <img class="icon" src="@/assets/image/dataCenter/data-icon03.png" alt="">
          <div class="side-r">
            <p class="title">回款总额</p>
            <p class="num">{{TotalForm.total}}<i>元</i></p>
          </div>
        </div>
      </div>
    </div>

    <!-- 销售数据/用户数据 -->
    <div class="echarts-inner">
      <div class="dlex">
        <div class="tab-ul">
          <div class="tab-li" :class="{'tab-li-active' : tabIndex == 0}" @click="tabIndex = 0">
            <span>销售数据</span>
          </div>
          <div class="tab-li" :class="{'tab-li-active' : tabIndex == 1}" @click="tabIndex = 1">
            <span>用户数据</span>
          </div>
        
        </div>
        <div class="txt1">
          <a-tooltip placement="bottom" title="指本月份数据同去年同期（若当前日期为15号，则去年同期截至到15号的数据）的数据对比。" :get-popup-container="getPopupContainer">
            <span>同比 <a-icon type="info-circle" /></span>
          </a-tooltip>
          <a-tooltip placement="bottom" title="指本月份数据同上一完整月份的数据对比。" :get-popup-container="getPopupContainer">
            <span>环比 <a-icon type="info-circle" /></span>
          </a-tooltip>
        </div>
      </div>
      <!-- 销售数据 -->
      <div class="template" v-if="tabIndex == 0">
        <!-- 商品回款数据 -->
        <div class="public-side first-line">
          <div class="job-item-data collection-item-data">
            <div class="flex-between">
              <div class="flex-between">
                <a-tooltip placement="bottom" title="根据时间计算所有回款的总和，包括已退款订单。" :get-popup-container="getPopupContainer">
                 <h4 class="h4-title">商品回款数据 <a-icon type="info-circle" /></h4>
                </a-tooltip>
              </div>
              <div class="box_time">
                <div class="time-item" :class="{'time-item-hover' : ReturnIndex == item.index}" @click="onSearchClick((item.index), 'Return')" v-for="(item) in timeList2" :key="item.index + '$02'">{{item.name}}</div>
                <!-- <a-date-picker 
                  v-model="ReturnYear" 
                  :open='ReturnPickerShow'  
                  @openChange="openChange($event,'Return')" 
                  @panelChange="panelChange($event,'Return')" 
                  @change="clearChange('Return')"
                  placeholder="年度" 
                  format="YYYY" 
                  class="picker" 
                  mode="year" /> -->
                <a-select mode="multiple" class="more-picker" v-model="yearList" @change="onMoreYearChange" allowClear placeholder="请选择年度">
                  <a-icon slot="suffixIcon" type="caret-down" />
                  <a-select-option :value="item" v-for="(item,index) in yearArry" :key="index">{{item}}</a-select-option>
                </a-select>
              </div>
            </div>
            <div>
              <span class="select s1">销售商品选择：</span>
              <span class="select s2" @click="openProductClick(1)">{{returnObj.name ? returnObj.name : '全部'}}</span>
              <span class="select s2" @click="onClearProduct(1)" v-if="returnObj.name"><a-icon type="close-circle"/></span>
            </div>
            <Collection-Chart v-if="yaerShow" :DataList="ReturnList" :status="ReturnIndex" />
            <div v-else style="height:320px"></div>
          </div>
          <div class="right-box">
            <div class="job-item-data right-job-01">
              <a-tooltip placement="bottom" title="按月份统计本月份的所有回款的总和，包括已退款订单。" :get-popup-container="getPopupContainer">
                <h4 class="h4-title">销售回款 <a-icon type="info-circle" /></h4>
              </a-tooltip>
              <div class="time">
                <a-date-picker 
                  v-model="SalesPriceYear" 
                  :allowClear="false"
                  :open='SalesPricePickerShow'
                  @openChange="openChange($event,'SalesPrice')" 
                  @panelChange="panelMonthChange($event,'SalesPrice')"
                  placeholder="请选择月份" 
                  format="YYYY-MM" 
                  class="picker" 
                  mode="month" />
              </div>
              <img class="icon" src="@/assets/image/dataCenter/data-icon04.png" alt="">
              <div class="price">
                <p>{{SalesPriceForm.price}}<i>元</i></p>
              </div>
              <div class="">
                <div class="weight">同比：
                  <template v-if="SalesPriceForm.yoy == '999999.00'">
                    -
                  </template>
                  <template v-else>
                    <img :src="SalesPriceForm.yoy > 0 ? upIcon : downIcon" alt=""><i>{{(SalesPriceForm.yoy * 100).toFixed(0)}}%</i>
                  </template>
                </div>
                <div class="weight">环比：
                  <template v-if="SalesPriceForm.qoq == '999999.00'">
                    -
                  </template>
                  <template v-else>
                    <img :src="SalesPriceForm.qoq > 0 ? upIcon : downIcon" alt=""><i>{{(SalesPriceForm.qoq * 100).toFixed(0)}}%</i>
                  </template>
                </div>
              </div>
            </div>
            <div class="job-item-data right-job-02">
              <div class="title">
                <a-tooltip placement="bottom" title="指课程在某月份的的全部回款总和，包括已注销/已退款用户但存在课程的数据。" :get-popup-container="getPopupContainer">
                  <span class="h4-title">新增</span>
                  <span class="span" @click="openProductClick(2)">{{CourseCompareObj.name}}</span>
                  <span class="h4-title">报名人数、金额 <a-icon type="info-circle" /></span>
                </a-tooltip>
              </div>
              <div class="time">
                <a-date-picker 
                  v-model="CourseCompareYear" 
                  :allowClear="false"
                  :open='CourseComparePickerShow'
                  @openChange="openChange($event,'CourseCompare')" 
                  @panelChange="panelMonthChange($event,'CourseCompare')"
                  placeholder="请选择月份" 
                  format="YYYY-MM" 
                  class="picker" 
                  mode="month" />
              </div>
              <div class="flex-between">
                <div class="item-inner">
                  <img class="icon" src="@/assets/image/dataCenter/data-icon05.png" alt="">
                  <div class="price">
                    <p>{{CourseCompareForm.data1.people}}<i>人</i></p>
                  </div>
                  <div class="">
                    <div class="weight">同比：
                      <template v-if="CourseCompareForm.data1.yoy == '999999.00'">
                        -
                      </template>
                      <template v-else>
                        <img :src="CourseCompareForm.data1.yoy > 0 ? upIcon : downIcon" alt=""><i>{{(CourseCompareForm.data1.yoy * 100).toFixed(0)}}%</i>
                      </template>
                    </div>
                    <div class="weight">环比：
                      <template v-if="CourseCompareForm.data1.qoq == '999999.00'">
                        -
                      </template>
                      <template v-else>
                        <img :src="CourseCompareForm.data1.qoq > 0 ? upIcon : downIcon" alt=""><i>{{(CourseCompareForm.data1.qoq * 100).toFixed(0)}}%</i>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="line"></div>
                <div class="item-inner">
                  <img class="icon" src="@/assets/image/dataCenter/data-icon06.png" alt="">
                  <div class="price">
                    <p>{{CourseCompareForm.data2.price}}<i>元</i></p>
                  </div>
                  <div class="">
                    <div class="weight">同比：
                      <template v-if="CourseCompareForm.data2.yoy == '999999.00'">
                        -
                      </template>
                      <template v-else>
                        <img :src="CourseCompareForm.data2.yoy > 0 ? upIcon : downIcon" alt=""><i>{{(CourseCompareForm.data2.yoy * 100).toFixed(0)}}%</i>
                      </template>
                    </div>
                    <div class="weight">环比：
                      <template v-if="CourseCompareForm.data2.qoq == '999999.00'">
                        -
                      </template>
                      <template v-else>
                        <img :src="CourseCompareForm.data2.qoq > 0 ? upIcon : downIcon" alt=""><i>{{(CourseCompareForm.data2.qoq * 100).toFixed(0)}}%</i>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 课程报名情况 -->
        <div class="public-side second-line">
          <div class="job-item-data">
            <div class="matrix-echarts">
              <div class="flex-between">
                <h4 class="h4-title">课程报名情况</h4>
                <div class="box_time">
                  <div class="time-item" :class="{'time-item-hover' : SignupIndex == item.index}" @click="onSearchClick(item.index, 'Signup')" v-for="(item) in timeList" :key="item.index + '$03'">{{item.name}}</div>
                  <a-date-picker 
                    v-model="SignupYear" 
                    :open='SignupPickerShow'  
                    @openChange="openChange($event,'Signup')" 
                    @panelChange="panelChange($event,'Signup')" 
                    @change="clearChange('Signup')"
                    placeholder="年度" 
                    format="YYYY" 
                    class="picker" 
                    mode="year" />
                </div>
              </div>
              <p class="tips">(矩形树图中仅展示各课程分类下报名排行前五的学习人数数据,明细见右侧表格)</p>
              <Signup-Chart :DataList="SignupList" />
            </div>
            <!-- 课程报名情况-跑马灯效果 -->
            <div class="course-lamp">
              <div class="tr head-tr flex-between">
                <span class="th one">序号</span>
                <span class="th two">课程名称</span>
                <span class="th three">课程分类</span>
                <span class="th three">报名人数
                  <i :class="{'i-color' : signupNum == 1}" @click="fun_sort(signupLampList, 'signup', 'value', 'down'),signupNum = 1">↓</i>
                  <i :class="{'i-color' : signupNum == 2}" @click="fun_sort(signupLampList, 'signup', 'value', 'up'),signupNum = 2">↑</i>
                </span>
                <span class="th four">消费总额(元)
                  <i :class="{'i-color' : signupNum == 3}" @click="fun_sort(signupLampList, 'signup', 'price', 'down'),signupNum = 3">↓</i>
                  <i :class="{'i-color' : signupNum == 4}" @click="fun_sort(signupLampList, 'signup', 'price', 'up'),signupNum = 4">↑</i>
                </span>
              </div>
              <div class="seamless-scroll">
                <vue-seamless-scroll v-if="signupShow" :data="signupLampList" :class-option="LampOption">
                  <div class="tr list-tr flex-between" :class="{'list-tr-active' : index % 2 == 0}" v-for="(item,index) in signupLampList" :key="index">
                    <span class="td one">{{index + 1}}</span>
                    <span class="td two" :title="item.name">{{item.name}}</span>
                    <span class="td three">{{item.categorName}}</span>
                    <span class="td three">{{item.value}}</span>
                    <span class="td four">{{item.price}}</span>
                  </div>
                </vue-seamless-scroll>
              </div>
            </div>
          </div>
        </div>
        <!-- 机构销售明细 -->
        <div class="public-side third-line">
          <div class="job-item-data">
            <h4 class="h4-title">机构销售明细</h4>
            <a-range-picker v-model="OrgYear" :disabledDate="fun_disDate" :allowClear="false" @change="selectChange" />
            <div class="course-lamp flex-between">
              <div class="scroll-item side-01">
                <h5 class="h5-title01">
                  <a-tooltip placement="bottom" title="根据筛选的时间范围，计算这个时间范围内的机构总体消费情况。" :get-popup-container="getPopupContainer">
                    机构消费排行 <a-icon type="info-circle" />
                  </a-tooltip>
                </h5>
                <div class="tr head-tr flex-between">
                  <span class="th one">序号</span>
                  <span class="th two">机构名称</span>
                  <span class="th three">消费总额(元)
                    <i :class="{'i-color' : orgConsumeNum == 1}" @click="fun_sort(orgConsumeLampList, 'orgConsume', 'price', 'down'),orgConsumeNum = 1">↓</i>
                    <i :class="{'i-color' : orgConsumeNum == 2}" @click="fun_sort(orgConsumeLampList, 'orgConsume', 'price', 'up'),orgConsumeNum = 2">↑</i>
                  </span>
                  <span class="th four">销售数量
                    <i :class="{'i-color' : orgConsumeNum == 3}" @click="fun_sort(orgConsumeLampList, 'orgConsume', 'value', 'down'),orgConsumeNum = 3">↓</i>
                    <i :class="{'i-color' : orgConsumeNum == 4}" @click="fun_sort(orgConsumeLampList, 'orgConsume', 'value', 'up'),orgConsumeNum = 4">↑</i>
                  </span>
                </div>
                <div class="seamless-scroll">
                  <vue-seamless-scroll v-if="orgConsumeShow" :data="orgConsumeLampList" :class-option="LampOption">
                    <div class="tr list-tr flex-between" v-for="(item,index) in orgConsumeLampList" :key="index">
                      <span class="td one">{{index + 1}}</span>
                      <span class="td two" :title="item.name">{{item.name}}</span>
                      <span class="td three">{{item.price}}</span>
                      <span class="td four">{{item.value}}</span>
                    </div>
                  </vue-seamless-scroll>
                </div>
              </div>
              <div class="scroll-item side-02">
                <h5 class="h5-title02">
                  <a-tooltip placement="bottom" title="根据筛选的时间范围，计算这个时间范围内的单笔订单中各商品的消费情况。" :get-popup-container="getPopupContainer">
                   机构商品销量排行 <a-icon type="info-circle" />
                  </a-tooltip>
                </h5>
                <div class="tr head-tr flex-between">
                  <span class="th one">序号</span>
                  <span class="th two">商品名称</span>
                  <span class="th three">机构名称</span>
                  <span class="th four">消费总额(元)
                    <i :class="{'i-color' : orgProductNum == 1}" @click="fun_sort(orgProductLampList, 'orgProduct', 'price', 'down'),orgProductNum = 1">↓</i>
                    <i :class="{'i-color' : orgProductNum == 2}" @click="fun_sort(orgProductLampList, 'orgProduct', 'price', 'up'),orgProductNum = 2">↑</i>
                  </span>
                  <span class="th five">销售数量
                    <i :class="{'i-color' : orgProductNum == 3}" @click="fun_sort(orgProductLampList, 'orgProduct', 'value', 'down'),orgProductNum = 3">↓</i>
                    <i :class="{'i-color' : orgProductNum == 4}" @click="fun_sort(orgProductLampList, 'orgProduct', 'value', 'up'),orgProductNum = 4">↑</i>
                  </span>
                </div>
                <div class="seamless-scroll">
                  <vue-seamless-scroll v-if="orgProductShow" :data="orgProductLampList" :class-option="LampOption">
                    <div class="tr list-tr flex-between" v-for="(item,index) in orgProductLampList" :key="index">
                      <span class="td one">{{index + 1}}</span>
                      <span class="td two" :title="item.productName">{{item.productName}}</span>
                      <span class="td three" :title="item.name">{{item.name}}</span>
                      <span class="td four">{{item.price}}</span>
                      <span class="td five">{{item.value}}</span>
                    </div>
                  </vue-seamless-scroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 用户数据 -->
      <div class="template" v-if="tabIndex == 1">
        <!-- pv、uv统计 -->
        <div class="public-side fourth-line">
          <div class="job-item-data job-item-line01">
            <div class="title flex-between">
              <h4 class="h4-title">
                 <a-tooltip placement="bottom" title="pv：指这个时间内所有用户的登录app次数之和，包括同一用户重复登录的次数；uv（iOS）：指这个时间内登录app的iOS系统用户之和；uv（Android）：指这个时间内登录app的Android系统用户之和。" :get-popup-container="getPopupContainer">
                   pv、uv统计 <a-icon type="info-circle" />
                 </a-tooltip>
              </h4>
              <div class="box_time">
                <div class="time-item" :class="{'time-item-hover' : PVUVIndex == item.index}" @click="onSearchClick((item.index), 'PVUV')" v-for="(item) in timeList3" :key="item.index + '$09'">{{item.name}}</div>
                <a-date-picker 
                  v-model="PVUVYear" 
                  :open='PVUVPickerShow'  
                  @openChange="openChange($event,'PVUV')" 
                  @panelChange="panelChange($event,'PVUV')" 
                  @change="clearChange('PVUV')"
                  placeholder="年度" 
                  format="YYYY" 
                  class="picker" 
                  mode="year" />
              </div>
            </div>
            <PvUvChart :DataList="PVUVForm" />
          </div>
          <div class="job-item-right">
            <div class="job-item-data">
              <div class="title">
                <h4 class="h4-title">新增注册人数</h4>
                <div class="time">
                  <a-date-picker 
                    v-model="NewUserYear" 
                    :allowClear="false"
                    :open='NewUserPickerShow'
                    @openChange="openChange($event,'NewUser')" 
                    @panelChange="panelMonthChange($event,'NewUser')"
                    placeholder="请选择月份" 
                    format="YYYY-MM" 
                    class="picker" 
                    mode="month" />
                </div>
              </div>
              <div class="item-inner">
                <img class="icon" src="@/assets/image/dataCenter/data-icon05.png" alt="">
                <div class="price">
                  <p>{{NewUserForm.people}} <i>人</i></p>
                </div>
                <div class="">
                  <div class="weight">同比：
                    <template v-if="NewUserForm.yoy == '999999.00'">
                      -
                    </template>
                    <template v-else>
                      <img :src="NewUserForm.yoy > 0 ? upIcon : downIcon" alt=""><i>{{(NewUserForm.yoy * 100).toFixed(0)}}%</i>
                    </template>
                  </div>
                  <div class="weight">环比：
                    <template v-if="NewUserForm.qoq == '999999.00'">
                      -
                    </template>
                    <template v-else>
                      <img :src="NewUserForm.qoq > 0 ? upIcon : downIcon" alt=""><i>{{(NewUserForm.qoq * 100).toFixed(0)}}%</i>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="job-item-data">
              <div class="title">
                <h4 class="h4-title">
                 <a-tooltip placement="bottom" title="指某月份所有登录app端的用户之和。" :get-popup-container="getPopupContainer">
                   新增月活人数 <a-icon type="info-circle" />
                 </a-tooltip>
                </h4>
                <div class="time">
                  <a-date-picker 
                    v-model="MonthActiveYear" 
                    :allowClear="false"
                    :open='MonthActivePickerShow'
                    @openChange="openChange($event,'MonthActive')" 
                    @panelChange="panelMonthChange($event,'MonthActive')"
                    placeholder="请选择月份" 
                    format="YYYY-MM" 
                    class="picker" 
                    mode="month" />
                </div>
              </div>
              <div class="item-inner">
                <img class="icon" src="@/assets/image/dataCenter/data-icon05.png" alt="">
                <div class="price">
                  <p>{{MonthActiveForm.people}} <i>人</i></p>
                </div>
                <div class="">
                  <div class="weight">同比：
                    <template v-if="MonthActiveForm.yoy == '999999.00'">
                      -
                    </template>
                    <template v-else>
                      <img :src="MonthActiveForm.yoy > 0 ? upIcon : downIcon" alt=""><i>{{(MonthActiveForm.yoy * 100).toFixed(0)}}%</i>
                    </template>
                  </div>
                  <div class="weight">环比：
                    <template v-if="MonthActiveForm.qoq == '999999.00'">
                      -
                    </template>
                    <template v-else>
                      <img :src="MonthActiveForm.qoq > 0 ? upIcon : downIcon" alt=""><i>{{(MonthActiveForm.qoq * 100).toFixed(0)}}%</i>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 新增用户 -->
        <div class="public-side fifth-line">
          <div class="job-item-data">
            <div class="flex-between">
              <h4 class="h4-title">新增用户</h4>
              <div class="box_time">
                <div class="time-item" :class="{'time-item-hover' : UserIndex == item.index}" @click="onSearchClick(item.index, 'User')" v-for="(item) in timeList2" :key="item.index + '$04'">{{item.name}}</div>
                <a-date-picker 
                  v-model="UserYear" 
                  :open='UserPickerShow'  
                  @openChange="openChange($event,'User')" 
                  @panelChange="panelChange($event,'User')" 
                  @change="clearChange('User')"
                  placeholder="年度" 
                  format="YYYY" 
                  class="picker" 
                  mode="year" />
              </div>
            </div>
            <AdduserChart :DataList="UserForm" />
          </div>
        </div>

        
        <div class="flex-between eight-line">
          <!-- 活跃人数 -->
          <div class="public-side">
            <div class="job-item-data">
              <div class="flex-between">
                <h4 class="h4-title">
                  <a-tooltip placement="bottom" title="启动过应用的用户（去重），启动过一次的用户即视为活跃用户，包括新用户与老用户。" :get-popup-container="getPopupContainer">
                   活跃人数 <a-icon type="info-circle" />
                 </a-tooltip> 
                </h4>
                <div class="box_time">
                  <a-range-picker v-model="ActiveYear" :disabledDate="fun_disDate" :allowClear="false" @change="selectActiveChange($event, 1)" />
                </div>
              </div>
              <ActivityChart :DataList="ActiveForm" />
            </div>
          </div>
          <!-- 总人数 -->
          <div class="public-side">
            <div class="job-item-data">
              <div class="flex-between">
                <h4 class="h4-title">
                  <a-tooltip placement="bottom" title="截止到当前时间，启动过应用的所有独立用户（去重，以设备为判断标准），其中ios在22年8月开发默认加入统计功能，Android在23年9月上线统计功能。" :get-popup-container="getPopupContainer">
                   总人数 <a-icon type="info-circle" />
                 </a-tooltip> 
                </h4>
                <div class="box_time">
                  <a-range-picker v-model="TotalPeopleYear" :disabledDate="fun_disDate" :allowClear="false" @change="selectActiveChange($event, 2)" />
                </div>
              </div>
              <ActivityChart :DataList="TotalPeopleForm" />
            </div>
          </div>
        </div>

        <div class="flex-between eight-line">
          <!-- 启动数 -->
          <div class="public-side">
            <div class="job-item-data">
              <div class="flex-between">
                <h4 class="h4-title">
                  <a-tooltip placement="bottom" title="打开应用视为启动。完全退出或后台运行超过30s后再次进入应用，视为一次新启动。开发过程中可以通过setSessionContinueMills来自定义两次启动的间隔，默认30s。" :get-popup-container="getPopupContainer">
                   启动数 <a-icon type="info-circle" />
                 </a-tooltip> 
                </h4>
                <div class="box_time">
                  <a-range-picker v-model="StartsYear" :disabledDate="fun_disDate" :allowClear="false" @change="selectActiveChange($event, 3)" />
                </div>
              </div>
              <ActivityChart :DataList="StartsForm" />
            </div>
          </div>

          <!-- 新增用户数 -->
          <div class="public-side">
            <div class="job-item-data">
              <div class="flex-between">
                <h4 class="h4-title">
                  <a-tooltip placement="bottom" title="第一次启动应用的用户（以设备为判断标准）。" :get-popup-container="getPopupContainer">
                   新增用户数 <a-icon type="info-circle" />
                 </a-tooltip> 
                </h4>
                <div class="box_time">
                  <a-range-picker v-model="NewAddYear" :disabledDate="fun_disDate" :allowClear="false" @change="selectActiveChange($event, 4)" />
                </div>
              </div>
              <ActivityChart :DataList="NewAddForm" />
            </div>
          </div>
        </div>

        <!-- 身份分布/购买统计 -->
        <div class="public-side flex-between sixth-line">
          <!-- 身份分布 -->
          <div class="job-item-data">
            <div class="flex-between"> 
              <h4 class="h4-title">
                 <a-tooltip placement="bottom" title="指在筛选时间（认证/注册时间）内的人员认证/注册之和。" :get-popup-container="getPopupContainer">
                   身份分布 <a-icon type="info-circle" />
                 </a-tooltip> 
              </h4>
              <div class="box_time">
                <div class="time-item" :class="{'time-item-hover' : RoleIndex == item.index}" @click="onSearchClick(item.index, 'Role')" v-for="(item) in timeList" :key="item.index + '$05'">{{item.name}}</div>
                <a-date-picker 
                  v-model="RoleYear" 
                  :open='RolePickerShow'
                  @openChange="openChange($event,'Role')" 
                  @panelChange="panelChange($event,'Role')" 
                  @change="clearChange('Role')"
                  placeholder="年度" 
                  format="YYYY" 
                  class="picker" 
                  mode="year" />
              </div>
            </div>
            <Pie-Chart :DataList="RoleList" />
          </div>
          <!-- 购买统计 -->
          <div class="job-item-data">
            <div class="flex-between">
              <h4 class="h4-title">
                <a-tooltip placement="bottom" title="未购：指筛选时间内未购买任何商品的某角色用户；已购：指筛选时间内有购买随意商品的某角色用户。" :get-popup-container="getPopupContainer">
                   购买统计 <a-icon type="info-circle" />
                 </a-tooltip>
              </h4>
              <div class="box_time">
                <div class="time-item" :class="{'time-item-hover' : BuyIndex == item.index}" @click="onSearchClick(item.index, 'Buy')" v-for="(item) in timeList" :key="item.index + '$06'">{{item.name}}</div>
                <a-date-picker 
                  v-model="BuyYear" 
                  :open='BuyPickerShow'  
                  @openChange="openChange($event,'Buy')" 
                  @panelChange="panelChange($event,'Buy')" 
                  @change="clearChange('Buy')"
                  placeholder="年度" 
                  format="YYYY" 
                  class="picker" 
                  mode="year" />
              </div>
            </div>
            <Bar-Chart :DataList="BuyList" />
          </div>
        </div>

        <!-- 省份报名人数 -->
        <div class="public-side seventh-line">
          <div class="job-item-data">
            <div class="flex-between">
              <div class="flex-between">
                <h4 class="h4-title">
                  <a-tooltip placement="bottom" title="指筛选时间内购买指定商品的人员地区分布之和。" :get-popup-container="getPopupContainer">
                   省份报名人数 <a-icon type="info-circle" />
                 </a-tooltip>
                </h4>
                <div class="select">
                  <span>销售商品选择：</span>
                  <span class="s1" @click="openProductClick(3)">{{mapObj.name ? mapObj.name : '全部'}}</span>
                  <span class="s2" @click="onClearProduct(2)" v-if="mapObj.name"><a-icon type="close-circle"/></span>
                </div>
                <div class="address">
                  <img src="@/assets/image/address.png" alt="">
                  未知省份报名：{{unknown}}人
                </div>
              </div>
              <div class="box_time">
                <div class="time-item" :class="{'time-item-hover' : ProvinceSignupIndex == item.index}" @click="onSearchClick(item.index, 'ProvinceSignup')" v-for="(item) in timeList" :key="item.index + '$07'">{{item.name}}</div>
                <a-date-picker 
                  v-model="ProvinceSignupYear" 
                  :open='ProvinceSignupPickerShow'  
                  @openChange="openChange($event,'ProvinceSignup')" 
                  @panelChange="panelChange($event,'ProvinceSignup')" 
                  @change="clearChange('ProvinceSignup')"
                  placeholder="年度" 
                  format="YYYY" 
                  class="picker" 
                  mode="year" />
              </div>
            </div>
            <div class="map-div">
              <MapChart :DataList="ProvinceSignupList" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新建编辑弹窗 -->
    <a-modal v-model="productShow" width='1000px' title="选择商品课程"  :centered='true' :closable='false'>
      <div class="search-box">
        <a-input class="search-input" v-model="productForm.name" placeholder="请输入商品名称" />
        <a-button class="search-btn" type="primary" @click="onProductSearch">搜索</a-button>
      </div>

      <div class="table-box">
        <a-table class="table-template1" 
          :row-selection="{
            columnTitle: '选择',
            selectedRowKeys: selectedProductRowKeys,
            onChange: onSelectProduct,
            type: 'radio',
            columnWidth: '70px',
          }" 
          bordered
          :rowKey="item=> item.productId"
          :columns="productcolumns" 
          :data-source="productData"
          :loading="productLoad"
          @change="onProductPage"
          :pagination="{
            total:productTotal,
            current:productForm.page,  
            defaultpageSize:productForm.size, 
            showSizeChanger: true,
            showTotal: function(productTotal, range){
              return `共${productTotal}条`
            }
          }">
          <template slot="index" slot-scope="item, row, index">
            {{ (productForm.page - 1) * productForm.size + index + 1 }}
          </template>
         
          <!-- 商品类型 -->
          <template slot="productType" slot-scope="item">
            <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
            <span v-if="item==1">课程</span>
            <span v-else-if="item==2">图书</span>
            <span v-else-if="item==3">教具</span>
            <span v-else-if="item==4">模拟考试</span>
            <span v-else-if="item==5">电子照片</span>
            <span v-else-if="item==6">延期</span>
            <span v-else-if="item==7">补考</span>
            <span v-else-if="item==8">直播</span>
            <span v-else-if="item==9">其他</span>
            <span v-else>-</span>
          </template>
        </a-table>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="onAsk">确认</a-button>
        <a-button @click="productShow = false">取消</a-button>
      </template>

    </a-modal>
    
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll';  // 引入跑马灯组件

import CollectionChart from '@/components/Echarts/CollectionChart.vue' // 引入商品回款折线图组件
import SignupChart from '@/components/Echarts/SignupChart.vue' // 引入课程报名矩阵树图组件
import PvUvChart from '@/components/Echarts/PvUvChart.vue' // 引入pv、uv统计折线图组件
import AdduserChart from '@/components/Echarts/AdduserChart.vue' // 引新增用户折线图组件
import ActivityChart from '@/components/Echarts/ActivityChart.vue' // 引活量折线图组件
import PieChart from '@/components/Echarts/PieChart.vue' // 引入身份分布饼图组件
import BarChart from '@/components/Echarts/BarChart.vue' // 引入购买统计柱图组件
import MapChart from '@/components/Echarts/MapChart.vue' // 引入省份报名人数地图组件

const upIcon = require('@/assets/image/dataCenter/icon-up.png')
const downIcon = require('@/assets/image/dataCenter/icon-down.png')

const productcolumns = [{
    title: "序号",
    align: "center",
    width:'70px',
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品类型",
    align: "center",
    width:'140px',
    dataIndex: "productType",
    scopedSlots: {
      customRender: "productType",
    },
  },
  {
    title: "商品名称",
    ellipsis:true,
    align: "center",
    dataIndex: "productName",
  },
];

import methods from './methods.vue'
export default {
  mixins: [ methods ],
  // 可用组件的哈希表
  components: { vueSeamlessScroll, CollectionChart, SignupChart, PvUvChart, AdduserChart, ActivityChart, PieChart, BarChart, MapChart },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      hahhaa:true,
      productcolumns,
      upIcon,
      downIcon,
      yearList: [],
      yearArry:[], // 20年至今年所有年份
      yaerShow: true,

      tabIndex: 0, // 0 销售数据 1 用户数据

      timeList:[ // 时间筛选项 带全部按钮
        { index:0,name:'全部' }, { index:1,name:'本日' }, { index:2,name:'本周' }, { index:3,name:'本月' },
      ],
      timeList2:[
        { index:1,name:'本日' }, { index:2,name:'本周' }, { index:3,name:'本月' },
      ], // 时间筛选项

      timeList3:[
        { index:2,name:'本周' }, { index:3,name:'本月' },
      ], // 时间筛选项

      /* 时间筛选项下标 */
      TotalIndex:3,  // 总体数据
      ReturnIndex:3,  // 回款数据
      SignupIndex:3,  // 报名数据
      PVUVIndex:3,  // pv、uv统计数据
      UserIndex:3,  // 新增用户数据
      RoleIndex:3,  // 身份分布数据
      BuyIndex:3,  // 购买统计数据
      ProvinceSignupIndex:3,  // 省份报名人数数据

      /* 日期选择框 打开/关闭 状态 */ 
      TotalPickerShow: false, // 总体数据
      ReturnPickerShow: false, // 回款数据
      SalesPricePickerShow: false, // 销售回款数据
      CourseComparePickerShow: false, // 报名人数、金额数据
      SignupPickerShow: false, // 报名数据
      PVUVPickerShow: false, // pv、uv统计数据
      NewUserPickerShow: false, // 新增注册数据
      MonthActivePickerShow: false, // 新增月活数据
      UserPickerShow: false, // 新增用户数据
      RolePickerShow: false, // 身份分布数据
      BuyPickerShow: false, // 购买统计数据
      ProvinceSignupPickerShow: false, // 省份报名人数数据

      /* 年度绑定数据 */
      TotalYear: null, // 总体数据
      ReturnYear: null, // 回款数据
      SalesPriceYear: null, // 销售回款数据
      CourseCompareYear: null, // 报名人数、金额数据
      SignupYear: null, // 报名数据
      OrgYear: null, // 机构消费排行
      PVUVYear: null, // pv、uv统计数据
      NewUserYear: null, // 新增注册数据
      MonthActiveYear: null, // 新增月活数据
      ActiveYear: null, // 活跃人数数据
      TotalPeopleYear: null, // 总人数数据
      StartsYear: null, // 启动数数据
      NewAddYear: null, // 新增人数数据
      UserYear: null, // 新增用户数据
      RoleYear: null, // 身份分布数据
      BuyYear: null, // 购买统计数据
      ProvinceSignupYear: null, // 省份报名人数数据

      /* 图表数据 */
      TotalForm: {
        number1: 0,  // 平台注册人数（个人）
        number2: 0,  // 平台注册人数（机构）
        total: 0,  // 回款总额
      }, // 总体数据
      ReturnList: {
        legend: [],
        xData: [],
        series:[],
      }, // 回款数据
      SalesPriceForm: {
        price: 0, // 销售额
        yoy: 0, // 同比
        qoq: 0, // 环比
      }, // 销售回款数据
      CourseCompareForm: {
        data1:{
          people: 0, // 人数
          yoy: 0, // 同比
          qoq: 0, // 环比
        },
        data2:{
          price: 0, // 销售额
          yoy: 0, // 同比
          qoq: 0, // 环比
        }
      }, // 报名人数、金额数据
      SignupList: [], // 报名数据
      signupLampList: [], // 报名跑马灯数据
      signupShow: true, // 控制-报名跑马灯数据马灯显隐
      signupNum: 0, // 报名排序索引
      orgConsumeLampList: [], // 机构消费排行数据
      orgConsumeShow: true, // 控制-机构消费排行数据马灯显隐
      orgConsumeNum: 0, // 机构消费排行报名排序索引
      orgProductLampList: [], // 机构商品销量排行数据
      orgProductShow: true, // 控制-机构商品销量排行数据马灯显隐
      orgProductNum: 0, // 机构商品销量排行排序索引
      PVUVForm: {
        iosData: [],
        androidData: [],
        pvData: [],
        xData: [],
      }, // pv、uv统计数据
      NewUserForm: {
        people: 0, // 销售额
        yoy: 0, // 同比
        qoq: 0, // 环比
      }, // 新增注册数据
      ActiveForm: {
        iosData: [],
        androidData: [],
        totalData: [],
        xData: [],
      }, // 活跃人数数据
      TotalPeopleForm: {
        iosData: [],
        androidData: [],
        totalData: [],
        xData: [],
      }, // 总人数数据
      StartsForm: {
        iosData: [],
        androidData: [],
        totalData: [],
        xData: [],
      }, // 启动数数据
      NewAddForm: {
        iosData: [],
        androidData: [],
        totalData: [],
        xData: [],
      }, // 新增人数数据
      MonthActiveForm: {
        people: 0, // 销售额
        yoy: 0, // 同比
        qoq: 0, // 环比
      }, // 新增月活数据
      UserForm: {
        personData: [],
        orgData: [],
        xData: [],
      }, // 新增用户数据
      RoleList: [], // 身份分布数据
      BuyList: [], // 购买统计数据
      ProvinceSignupList: [], // 省份报名人数数据

      signupList:[],

      /* 跑马灯配置 */
      LampOption: {
        step: 0.2, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000
      },

      /* 商品课程配置 */
      productForm: {
        name:'',
        type: 1,  // 1.课程 2.图书 3.教具
        page:1,
        size:10,
      },
      selectedProductRowKeys: [],
      selectedProductDetail: {},
      productTotal: 0,
      productData: [],
      productLoad: true,
      btnLoad: false,
      productShow: false,
      productType: 0,
      
      returnObj: {
        key: 0,
        name: ''
      },
      CourseCompareObj: {
        key: 1,
        name: '',
        productId: 1,
      },
      mapObj: {
        key: 0,
        name: ''
      },
      
      returnProductId: [],
      courseCompareProductId: [1],
      MapProductId: [],

      unknown: 0, // 省份报名未知人数
    };
  },
  // 事件处理器
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.main-wrap {
  background-color: #f6f6fc !important;
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box_time{
    display: flex;
    align-items: center;
    .time-item{
      width: 64px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      background: #E5F0FF;
      border-radius: 3px 3px 3px 3px;
      margin-left: 30px;
      cursor: pointer;
    }
    .time-item-hover{
      background: #60A0FF;
      color: #ffffff;
      box-shadow: inset 0px 4px 4px 0px rgba(0,0,0,0.15);
    }
    .picker{
      margin-left: 20px;
      width: 90px;
    }
    .more-picker{
      width: 180px;
      margin-left: 30px;
    }
  }
    /* 总体数据 */
  .screen-data {
    padding: 0 20px;
    .head-title {
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 21px;
    }
    .data-ul{
      display: flex;
      justify-content: center;
      margin-top: 12px;
      .item-li{
        width: 25%;
        min-width: 330px;
        height: 97px;
        margin: 0 6px;
        background: #FFFFFF;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
        border-radius: 7px;
        display: flex;
        .icon{
          height: 100%;
        }
        .side-r{
          margin-left: 12px;
          .title{
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 19px;
            margin-top: 13px;
            margin-bottom: 12px;
          }
          .num{
            font-size: 32px;
            font-family: DIN Alternate-Bold, DIN Alternate;
            font-weight: bold;
            color: #333333;
            line-height: 38px;
            i{
              font-size: 14px;
              font-style: normal;
              color: rgba(51,51,51,0.7);
            }
          }
        }
      }
    }
  }


  /* 销售数据/用户数据 */
  .echarts-inner{
    margin-top: 20px;
    margin-right: 20px;
    .tab-ul{
      display: inline-block;
      background: #FFFFFF;
      padding: 3px;
      box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
      border-radius: 26px;
      overflow: hidden;
      .tab-li{
        float: left;
        text-align: center;
        width: 128px;
        height: 45px;
        border-radius: 23px;
        line-height: 45px;
        color: #666666;
        font-size: 18px;
        transition:  all .2s;
        cursor: pointer;
      }
      .tab-li-active{
        background: #347DEA;
        color: #ffffff;
      }
      
    }
    .dlex{
      display: flex;
      align-items: center;
      .txt1{
        margin-left: 40px;
        >span{
          margin: 0 20px;
        }
      }
    }
    .h4-title{
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 19px;
    }
    
    .public-side{
      margin-top: 12px;
    }

    .job-item-data{
      padding: 12px 20px;
      background: #FFFFFF;
      box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
      border-radius: 2px;
    }

    // 01 商品回款数据
    .first-line{
      display: flex;
      .collection-item-data{
        width: 55%;
        min-width: 630px;
        .box_time{
          flex: 1;
          justify-content: right;
        }
        .select{
          display: inline-block;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #347DEA;
          line-height: 16px;
        }
        .s2{
          margin-left: 8px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .right-box{
        flex: 1;
        margin-left: 12px;
        display: flex;
        .price{
          margin: 20px auto 28px;
          p{
            font-size: 28px;
            font-family: DIN Alternate-Bold, DIN Alternate;
            font-weight: bold;
            color: #333333;
            line-height: 33px;
          }
          i{
            font-style: normal;
            font-size: 14px;
            font-family: PingFang SC-Light, PingFang SC;
            font-weight: 300;
            color: #666666;
            line-height: 16px;
          }
        }
        .weight{
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
          margin-top: 21px;
          i{
            font-style: normal;
            font-size: 16px;
            color: #00C48C;
          }
          img{
            width: 10px;
            margin: -5px 8px 0 8px;
          }
        }
        .time{
          border-radius: 3px 3px 3px 3px;
          margin: 10px auto 30px;
          text-align: center;
        }
        .icon{
          width: 74px;
        }

        .right-job-01{
          width: 33%;
          min-width: 190px;
          text-align: center;
        }
        .right-job-02{
          flex: 1;
          margin-left: 8px;
          min-width: 340px;
          .title{
            display: flex;
            justify-content: center;
            align-items: center;
            .span{
              color: #276BCF;
              text-decoration: underline;
              cursor: pointer;
            }
          }
          .flex-between{
            text-align: center;
            .line{
              width: 1px;
              height: 180px;
              background: #F1F1FF;
            }
            .item-inner{
              width: calc((100% - 1px) / 2);
            }
          }
        }
      }
    }

    // 02 课程报名情况
    .second-line{
      .job-item-data{
        display: flex;
        .matrix-echarts{
          width: calc(55% - 20px);
          min-width: 630px;
          .tips{
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
          }
        }
        .course-lamp{
          flex: 1;
          width: 45%;
          margin-left: 32px;
          .tr{
            .th,.td{
              display: inline-block;
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 18px;
              text-align: center;
            }
            .one{
              width: 12%;
            }
            .two{
              flex: 1;
              overflow:hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow:ellipsis;
            }
            .three, .four{
              width: 17%;
              min-width: 100px;
              white-space: nowrap;
            }
            i{
              font-style: normal;
              font-weight: 800;
              cursor: pointer;
            }
            .i-color{
              color: #333333;
            }
          }
          .head-tr{
            background: #85b5ff;
            position: relative;
            z-index: 1;
            height: 30px;
            line-height: 30px;
            box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.08);
            border-radius: 5px 5px 0px 0px;
            .th{
              color: #ffffff;
            }
          }
          .seamless-scroll{
            margin-top: 20px;
            height: 340px;
            overflow: hidden;
            .list-tr{
              height: 34px;
              line-height: 34px;
              border-left: 1px solid #F8FAFB;
              border-right: 1px solid #F8FAFB;
            }
            .list-tr-active{
              background: #F8FAFB;
            }
          }
        }
      }
    }

    // 03 机构销售明细
    .third-line{
      .h4-title{
        margin-bottom: 12px;
      }
      .course-lamp{
        background: #F6F6FC;
        padding: 16px 18px;
        margin-top: 13px;
        .scroll-item{
          h5{
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 16px;
            margin-bottom: 12px;
            &::before{
              content: '';
              display: inline-block;
              width: 12px;
              height: 12px;
              background: #6ADFAF;
              margin-right: 3px;
            }
          }
          .h5-title02::before{
            background: #5B8FF9;
          }
          .head-tr{
            height: 30px;
            line-height: 30px;
            background: #85b5ff;
            box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.08);
            border-radius: 5px 5px 0px 0px;
            .th{
              color: #ffffff;
            }
          }
          .th,.td{
            display: inline-block;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
            text-align: center;
          }
          .seamless-scroll{
            margin-top: 4px;
            height: 340px;
            overflow: hidden;
            .list-tr{
              height: 30px;
              line-height: 30px;
              background: #FFFFFF;
              box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
              border-radius: 5px;
            }
          }
        }
        .side-01{
          width: 44%;
          .one{
            width: 13%;
          }
          .two{
            flex: 1;
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow:ellipsis;
          }
          .three, .four{
            width: 17%;
            min-width: 100px;
            white-space: nowrap;
          }
          i{
            font-style: normal;
            font-weight: 800;
            cursor: pointer;
          }
        }
        .side-02{
          margin-left: 20px;
          flex: 1;
          .one, .five, .four{
            width: 11%;
            min-width: 100px;
            white-space: nowrap;
          }
          .two, .three{
            flex: 1;
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow:ellipsis;
          }
          i{
            font-style: normal;
            font-weight: 800;
            cursor: pointer;
          }
        }
        .i-color{
          color: #333333;
        }
      }
    }

    // 04 pv、uv统计
    .fourth-line{
      display: flex;
      .job-item-line01{
        width: 75%;
        .title{
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .h4-title{
            margin-right: 20px;
          }
        }
      }
      .job-item-right{
        margin-left: 8px;
        flex: 1;
        display: flex;
        .job-item-data{
          width: calc(100%  / 2);
          text-align: center;
          min-width: 180px;
          .price{
            margin: 35px auto;
            p{
              font-size: 28px;
              font-family: DIN Alternate-Bold, DIN Alternate;
              font-weight: bold;
              color: #333333;
              line-height: 33px;
            }
            i{
              font-style: normal;
              font-size: 14px;
              font-family: PingFang SC-Light, PingFang SC;
              font-weight: 300;
              color: #666666;
              line-height: 16px;
            }
          }
          .weight{
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 14px;
            margin-top: 21px;
            i{
              font-style: normal;
              font-size: 16px;
              color: #00C48C;
            }
            img{
              width: 10px;
              margin: -5px 8px 0 8px;
            }
          }
          .time{
            width: 99px;
            height: 28px;
            background: #E5F0FF;
            border-radius: 3px 3px 3px 3px;
            margin: 10px auto 30px;
          }
          .icon{
            width: 74px;
          }
        }
      }
    }

    // 05 新增用户
    .fifth-line{
      .flex-between{
        margin-bottom: 20px;
      }
    }

    // 06 身份分布/购买统计
    .sixth-line{
      .flex-between{
        margin-bottom: 10px;
      }
      .job-item-data:first-child{
        width: 35%;
        min-width: 600px;
      }
      .job-item-data:last-child{
        width: 65%;
        margin-left: 12px;
        
      }
    }

    .seventh-line{
      .map-div{
        margin-top: 20px;
        padding-top: 10px;
        background: #F6F6FC;
      }
      .select{
        margin-left: 30px;
        >span{
          display: inline-block;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #347DEA;
          line-height: 16px;
          cursor: pointer;
        }
        .s1{
          text-decoration: underline;
        }
      }
      .address{
        margin-left: 30px;
        font-size: 14px;
        img{
          width: 14px;
          margin-top: -3px;
        }
      }
    }

    .eight-line{
      .public-side{
        width: calc((100% - 12px) / 2);
      }
    }
  }
}

.search-box{
  display: flex;
  .search-input{
    margin-right: 20px;
    margin-bottom: 30px;
  }
}
</style>
