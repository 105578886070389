<template>
  <!-- 省份报名人数-地图 -->
  <div>
    <div class="myChart-line" ref="myChart06"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import shengfen from "../../unit/china.json";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    DataList: {
      type: Array,
      default: () => [],
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    myChartInit() {
      var _that = this
      var charts = echarts.getInstanceByDom(this.$refs.myChart06);
      if (!charts) {
        charts = echarts.init(this.$refs.myChart06);
      }
    
      const option = {
        // 背景颜色
        backgroundColor: "#F6F6FC",
        // 提示浮窗样式
        tooltip: {
          show: true,
          trigger: "item", //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          alwaysShowContent: false,
          backgroundColor: "#fff",
          borderColor: "rgba(0, 0, 0, 0.16);",
          triggerOn: "mousemove",
          enterable: true, //鼠标是否可进入提示框浮层中
          textStyle: {
            fontSize: "12",
            overflow: "break",
          },
          formatter: function (params) {
            let str = "";
            str =
              `<div> ` + params.name + `：` + params.value + "人" + `</div>`;
            return str;
          },
        },
        visualMap: {
          //分段型视觉映射组件
          show: true,
          type: "piecewise",
          left: 50,
          bottom: 50,
          showLabel: true,
          itemWidth: 10,
          itemHeight: 10,
          inverse: true,
          // lt:小于; lte:小于等于; gt:大于; gte:大于等于;
          pieces: [
            {
              lt: 100,
              label: "<100人",
              color: "#8EF060",
            },
            {
              gte: 100,
              lte: 200,
              label: "100-200人",
              color: "#61D829",
            },
            {
              gt: 200,
              lte: 300,
              label: "200-300人",
              color: "#37AB00",
            },
            {
              gt: 300,
              lte: 400,
              label: "300-400人",
              color: "#FFC84B",
            },
            {
              gt: 400,
              lte: 500,
              label: "400-500人",
              color: "#FFB000",
            },
            {
              gt: 500,
              label: ">500人",
              color: "#EE6666",
            },
          ],
        },
        // 地图配置
        geo: {
          map: "china",
          aspectScale: 0.8, //长宽比,0.75的含义为宽是高的0.75,假如高为100,宽就只有75;0.5的含义就是宽只有高的一半,假如高为100,宽就只有50
          zoom: 1.6, //视觉比例大小,1.2即为原有大小的1.2倍
          roam: false, //是否开启鼠标缩放和平移漫游。默认不开启。可以不用设置,如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。
          top: "25%",
          label: {
            // 通常状态下的样式
            normal: {
              show: true,
              textStyle: {
                color: "#333",
              },
            },
            // 鼠标放上去的样式
            emphasis: {
              textStyle: {
                color: "#333",
              },
            },
          },
          // 地图区域的样式设置
          itemStyle: {
            normal: {
              borderColor: "rgba(0, 0, 0, 0.1)",
              borderWidth: 1,
            },
            // 鼠标放上去高亮的样式
            emphasis: {
              // areaColor: "rgba(0, 0, 0, 0.1)",
              borderWidth: 0,
            },
          },
        },
        series: [
          {
            selectedMode: false, //取消地图区域点击事件
            geoIndex: 0, //将数据和第0个geo配置关联在一起
            type: "map",
            data: _that.DataList,
          },
        ],
      };
      // 地图注册，第一个参数的名字必须和option.geo.map一致
      echarts.registerMap("china", shengfen);
      charts.setOption(option);
      window.addEventListener("resize", function () {
        charts.resize();
      });
      // charts.on("mouseover", function () {
      //   //取消鼠标移入地图区域高亮
      //   charts.dispatchAction({
      //     type: "legendUnSelect",
      //   });
      // });
    },
  },
  computed: {
    scrollerHeight: function () {
      return document.documentElement.clientHeight - 250 + "px"; //自定义高度
    },
    scrollerWeight: function () {
      return document.documentElement.clientWeight - 550 + "px"; //自定义宽度
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.myChartInit();
      }, 200);
    });
  },
  watch: {
    DataList() {
      this.$nextTick(() => {
        this.myChartInit();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myChart-line {
  min-width: 600px;
  height: 600px;
}
</style>
