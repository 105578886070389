<template>
  <!-- pvuv用户日活量-折线图 -->
  <div>
    <div class="myChart-line" ref="myChart03"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    DataList: {
      type: Object,
      default: () => {},
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    myChartInit() {
      var myChart = echarts.getInstanceByDom(this.$refs.myChart03);
      if (!myChart) {
        myChart = echarts.init(this.$refs.myChart03);
      }
      var option = {
        title: {
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["PV", "UV(ios)", "UV(Android)"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            saveAsImage: { show: true },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.DataList.xData,
        },
        yAxis: {
          type: "value",
          name: "单位（人）"
        },
        series: [
          {
            name: "PV",
            type: "line",
            stack: "Total",
            data: this.DataList.pvData,
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " 人";
              },
            },
            smooth: true, // 线条改曲线
          },
          {
            name: "UV(ios)",
            type: "line",
            stack: "Total",
            data: this.DataList.iosData,
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " 人";
              },
            },
            smooth: true, // 线条改曲线
          },
          {
            name: "UV(Android)",
            type: "line",
            stack: "Total",
            data: this.DataList.androidData,
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " 人";
              },
            },
            smooth: true, // 线条改曲线
          }
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.myChartInit();
      }, 200);
    });
  },
  watch: {
    DataList() {
      this.$nextTick(() => {
        this.myChartInit();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myChart-line {
  min-width: 700px;
  height: 300px;
}
</style>
