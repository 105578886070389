<template>
  <!-- 课程报名情况-矩阵树图 -->
  <div>
    <div class="myChart-line" ref="myChart02"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    DataList: {
      type: Array,
      default: () => [],
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    myChartInit() {
      var myChart = echarts.init(this.$refs.myChart02);
      var option = {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            // 自定义提示语
            const str = `${params.name}<br/>报名人数：${params.data.value} 人<br/>消费总额：${params.data.price} 元`;
            return str;
          },
        },
        
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            saveAsImage: { show: true },
          },
        },
        series: [
          {
            type: "treemap",
            data: this.DataList,
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize",function(){
        myChart.resize();
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.myChartInit();
      }, 200);
    });
  },
  watch: {
    DataList() {
      this.$nextTick(() => {
        this.myChartInit();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myChart-line {
  min-width: 600px;
  height: 400px;
}
</style>
