<template>
  <!-- 用户分布-饼图 -->
  <div>
    <div class="myChart-line" ref="myChart05"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    DataList: {
      type: Array,
      default: () => [],
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    myChartInit() {
      var myChart = echarts.getInstanceByDom(this.$refs.myChart05);
      if (!myChart) {
        myChart = echarts.init(this.$refs.myChart05);
      }
      var option = {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            // 自定义提示语
            const str = `${params.seriesName}<br/>${params.data.name}：${params.data.value} 人`;
            return str;
          },
        },
        
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            saveAsImage: { show: true },
          },
        },
        grid: {
          top: "80px"
        },
        series: [
          {
            name: "身份分布",
            type: "pie",
            radius: "50%",
            data: this.DataList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              normal: {
                show: true,
                formatter: "{b}: {c}人({d}%)",
                textStyle:{
                  fontSize: 13 
                }
              },
            },
            labelLine: {
              normal: {  // 设置 提示线样式
                length: 40, //第一条线
                lineStyle: {
                  width: 1, // 线条的宽度
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.myChartInit();
      }, 200);
    });
  },

  watch: {
    DataList() {
      this.$nextTick(() => {
        this.myChartInit();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myChart-line {
  min-width: 450px;
  height: 300px;
}
</style>
