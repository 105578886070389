import $ajax from './ajax.js'

/* -- 数据统计中心公共方法 -- */
export function getData (params) {
  return $ajax({
    url: '/hxclass-management/procedure/getProcedure',
    method: "POST",
    params: params
  })
}
/* -- 数据统计中心公共方法 END -- */